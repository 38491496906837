import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Schedule from './components/Schedule';
import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Login from './components/auth/Login';
import Signup from './components/auth/Signup';
import Dashboard from './components/Dashboard';
import ProtectedRoute from './components/ProtectedRoute';
import Lands from './components/Lands';
import LandDetails from './components/LandDetails';
import NewLand from './components/NewLand';
import NewSchedule from './components/NewSchedule';
import GenerateSchedule from './components/GenerateSchedule';

function MobileNav({ isOpen, setIsOpen }) {
  return (
    <div className={`fixed inset-0 bg-gray-900/50 backdrop-blur-sm transition-opacity ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
      <div className={`fixed inset-y-0 right-0 w-64 bg-white shadow-xl transition-transform ${isOpen ? 'translate-x-0' : 'translate-x-full'}`}>
        <div className="p-6">
          <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <div className="space-y-4 mt-8">
            <a href="#features" className="block text-gray-600 hover:text-green-600 transition-colors">Features</a>
            <a href="#about" className="block text-gray-600 hover:text-green-600 transition-colors">About</a>
            <a href="#contact" className="block text-gray-600 hover:text-green-600 transition-colors">Contact</a>
            <Link
              to="/signup"
              className="w-full bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 transition-colors"
            >
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="min-h-screen bg-gradient-to-b from-white to-green-50">
            {/* Navbar */}
            <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-green-100">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between h-16 items-center">
                  <div className="text-2xl font-bold bg-gradient-to-r from-green-600 to-green-400 bg-clip-text text-transparent">
                    FarmZen
                  </div>
                  <div className="hidden md:flex space-x-8">
                    <a href="#features" className="text-gray-600 hover:text-green-600 transition-colors">Features</a>
                    <a href="#about" className="text-gray-600 hover:text-green-600 transition-colors">About</a>
                    <a href="#contact" className="text-gray-600 hover:text-green-600 transition-colors">Contact</a>
                    <Link
                      to="/signup"
                      className="bg-green-600 text-white px-6 py-2 rounded-full hover:bg-green-700 transition-colors"
                    >
                      Get Started
                    </Link>
                  </div>
                  <button
                    onClick={() => setIsMobileMenuOpen(true)}
                    className="md:hidden text-gray-600 hover:text-gray-900"
                  >
                    <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  </button>
                </div>
              </div>
            </nav>
            <MobileNav isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />

            {/* Hero Section */}
            <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
              <div className="max-w-7xl mx-auto">
                <div className="text-center">
                  <h1 className="text-5xl md:text-6xl font-bold text-gray-900 mb-6 leading-tight">
                    Transform Your Farm with
                    <span className="bg-gradient-to-r from-green-600 to-green-400 bg-clip-text text-transparent"> Data-Driven </span>
                    Insights
                  </h1>
                  <p className="text-xl md:text-2xl text-gray-600 mb-10 max-w-3xl mx-auto">
                    Building the next generation of AI-powered farming tools to revolutionize crop selection and scheduling
                  </p>
                  <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <button className="bg-green-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-green-700 transition-all hover:scale-105 shadow-lg hover:shadow-green-200">
                      Join Early Access
                    </button>
                    <button className="bg-white text-green-600 px-8 py-4 rounded-full text-lg font-semibold border-2 border-green-600 hover:bg-green-50 transition-all hover:scale-105">
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Features Section */}
            <div className="py-20 px-4 sm:px-6 lg:px-8" id="features">
              <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center mb-16 text-gray-900">
                  Empower Your Farming Decisions
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                  {[
                    {
                      icon: "🌱",
                      title: "Smart Crop Selection",
                      description: "AI-powered recommendations based on your soil, climate, and market conditions"
                    },
                    {
                      icon: "📊",
                      title: "Data Analytics",
                      description: "Make informed decisions with comprehensive agricultural insights"
                    },
                    {
                      icon: "📅",
                      title: "Intelligent Scheduling",
                      description: "Generate optimized planting and harvesting schedules"
                    }
                  ].map((feature, index) => (
                    <div key={index} className="bg-white p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all hover:-translate-y-1 border border-green-100">
                      <div className="text-4xl mb-4">{feature.icon}</div>
                      <h3 className="text-xl font-semibold mb-4 text-gray-900">{feature.title}</h3>
                      <p className="text-gray-600">{feature.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* How It Works Section */}
            <div className="py-20 px-4 sm:px-6 lg:px-8 bg-white" id="about">
              <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center mb-16 text-gray-900">
                  How FarmZen Works
                </h2>
                <div className="grid md:grid-cols-4 gap-8">
                  {[
                    {
                      step: "01",
                      title: "Input Your Farm Data",
                      description: "Share your location, soil characteristics, and farming goals"
                    },
                    {
                      step: "02",
                      title: "AI Analysis",
                      description: "Our algorithms analyze climate patterns, market trends, and agricultural data"
                    },
                    {
                      step: "03",
                      title: "Get Recommendations",
                      description: "Receive personalized crop suggestions and optimal planting schedules"
                    },
                    {
                      step: "04",
                      title: "Continuous Optimization",
                      description: "Regular updates based on real-time data and changing conditions"
                    }
                  ].map((item, index) => (
                    <div key={index} className="relative">
                      <div className="text-5xl font-bold text-green-100 mb-4">{item.step}</div>
                      <h3 className="text-xl font-semibold mb-3 text-gray-900">{item.title}</h3>
                      <p className="text-gray-600">{item.description}</p>
                      {index !== 3 && (
                        <div className="hidden md:block absolute top-8 right-0 transform translate-x-1/2">
                          <svg className="w-12 h-12 text-green-200" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Technology Section */}
            <div className="py-20 px-4 sm:px-6 lg:px-8 bg-gradient-to-b from-green-50 to-white">
              <div className="max-w-7xl mx-auto">
                <div className="grid md:grid-cols-2 gap-12 items-center">
                  <div>
                    <h2 className="text-4xl font-bold mb-6 text-gray-900">
                      Powered by Advanced Technology
                    </h2>
                    <div className="space-y-6">
                      {[
                        {
                          title: "Machine Learning Algorithms",
                          description: "Advanced AI models analyze historical and real-time agricultural data"
                        },
                        {
                          title: "Climate Data Integration",
                          description: "Real-time weather monitoring and seasonal pattern analysis"
                        },
                        {
                          title: "Soil Analysis",
                          description: "Comprehensive soil composition and health assessment"
                        }
                      ].map((tech, index) => (
                        <div key={index} className="flex gap-4">
                          <div className="flex-shrink-0">
                            <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center">
                              <svg className="w-5 h-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                              </svg>
                            </div>
                          </div>
                          <div>
                            <h3 className="font-semibold text-lg mb-1">{tech.title}</h3>
                            <p className="text-gray-600">{tech.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="relative">
                    <div className="aspect-square rounded-2xl bg-gradient-to-br from-green-500 to-green-600 p-1">
                      <div className="w-full h-full bg-white rounded-xl p-8">
                        <div className="space-y-4">
                          <div className="h-4 bg-green-100 rounded-full w-3/4"></div>
                          <div className="h-4 bg-green-100 rounded-full w-1/2"></div>
                          <div className="h-32 bg-green-50 rounded-xl mt-8"></div>
                          <div className="grid grid-cols-2 gap-4">
                            <div className="h-20 bg-green-100 rounded-lg"></div>
                            <div className="h-20 bg-green-100 rounded-lg"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Benefits Section */}
            <div className="py-20 px-4 sm:px-6 lg:px-8 bg-white">
              <div className="max-w-7xl mx-auto">
                <h2 className="text-4xl font-bold text-center mb-16 text-gray-900">
                  Why Choose FarmZen
                </h2>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                  {[
                    {
                      icon: "💰",
                      title: "Increase Profitability",
                      description: "Optimize crop selection based on market demand and potential yield"
                    },
                    {
                      icon: "🌍",
                      title: "Sustainable Farming",
                      description: "Make environmentally conscious decisions with our AI recommendations"
                    },
                    {
                      icon: "⏱️",
                      title: "Save Time",
                      description: "Streamline your planning process with automated scheduling"
                    },
                    {
                      icon: "📈",
                      title: "Data-Driven Decisions",
                      description: "Access comprehensive insights for informed farming choices"
                    },
                    {
                      icon: "🔄",
                      title: "Adaptive Planning",
                      description: "Adjust strategies based on real-time conditions and forecasts"
                    },
                    {
                      icon: "🤝",
                      title: "Expert Support",
                      description: "Get assistance from our team of agricultural specialists"
                    }
                  ].map((benefit, index) => (
                    <div key={index} className="bg-green-50 p-8 rounded-2xl hover:bg-green-100 transition-colors">
                      <div className="text-4xl mb-4">{benefit.icon}</div>
                      <h3 className="text-xl font-semibold mb-3 text-gray-900">{benefit.title}</h3>
                      <p className="text-gray-600">{benefit.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Contact Section */}
            <div className="py-20 px-4 sm:px-6 lg:px-8 bg-white" id="contact">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-4xl font-bold mb-6 text-gray-900">
                  Have Questions?
                </h2>
                <p className="text-xl text-gray-600 mb-10">
                  Our team is here to help you understand how FarmZen can transform your farming operations
                </p>
                <div className="bg-green-50 p-8 rounded-2xl">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <a href="mailto:contact@farmzen.com" className="flex items-center justify-center gap-3 bg-white p-4 rounded-xl hover:shadow-md transition-shadow">
                      <svg className="w-6 h-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      <span className="text-gray-600">contact@farmzen.com</span>
                    </a>
                    <a href="tel:+1234567890" className="flex items-center justify-center gap-3 bg-white p-4 rounded-xl hover:shadow-md transition-shadow">
                      <svg className="w-6 h-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                      </svg>
                      <span className="text-gray-600">+25426502351</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <footer className="bg-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
              <div className="max-w-7xl mx-auto">
                <div className="grid md:grid-cols-4 gap-8">
                  <div>
                    <div className="text-2xl font-bold mb-4">FarmZen</div>
                    <p className="text-gray-400">Revolutionizing agriculture with AI-powered insights</p>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-4">Product</h3>
                    <ul className="space-y-2 text-gray-400">
                      <li><a href="#features" className="hover:text-green-400">Features</a></li>
                      <li><a href="#about" className="hover:text-green-400">How It Works</a></li>
                      <li><a href="#" className="hover:text-green-400">Pricing</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-4">Company</h3>
                    <ul className="space-y-2 text-gray-400">
                      <li><a href="#about" className="hover:text-green-400">About Us</a></li>
                      <li><a href="#contact" className="hover:text-green-400">Contact</a></li>
                      <li><a href="#" className="hover:text-green-400">Privacy Policy</a></li>
                    </ul>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-4">Connect</h3>
                    <div className="flex space-x-4">
                      <a href="#" className="text-gray-400 hover:text-green-400">
                        <span className="sr-only">Twitter</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                        </svg>
                      </a>
                      <a href="#" className="text-gray-400 hover:text-green-400">
                        <span className="sr-only">LinkedIn</span>
                        <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="mt-12 pt-8 border-t border-gray-800 text-center text-gray-400">
                  <p>&copy; 2024 FarmZen. All rights reserved.</p>
                </div>
              </div>
            </footer>

            {/* Add a link to the schedule page */}
            <div className="pt-32 pb-20 px-4 sm:px-6 lg:px-8">
              <div className="max-w-7xl mx-auto text-center">
                <Link
                  to="/schedule"
                  className="inline-block bg-green-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-green-700 transition-all hover:scale-105 shadow-lg hover:shadow-green-200"
                >
                  Try Schedule Generator
                </Link>
              </div>
            </div>
          </div>
        } />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/dashboard"
          element={
              <Dashboard />
          }
        />
        <Route path="/lands" element={<Lands />} />
        <Route path="/lands/:id" element={<LandDetails />} />
        <Route path="/lands/new" element={<NewLand />} />
        <Route path="/lands/:id/schedule/new" element={<NewSchedule />} />
        <Route path="/lands/:id/schedule/generate" element={<GenerateSchedule />} />
      </Routes>
    </Router>
  );
}

export default App;
