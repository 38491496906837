import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import DashboardLayout from './layout/DashboardLayout';
import axiosInstance from '../services/axios';

const ScheduleCard = ({ schedule, isExpanded, onToggle }) => {
  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-6">
        {/* Header - Always visible */}
        <div
          className="flex items-center justify-between cursor-pointer"
          onClick={onToggle}
        >
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-lg font-medium text-gray-900">
                  {schedule.crop_name} ({schedule.variety})
                </h3>
                <p className="text-sm text-gray-500">
                  {new Date(schedule.start_date).toLocaleDateString()} - {new Date(schedule.end_date).toLocaleDateString()}
                </p>
              </div>
              <div className="flex items-center space-x-3">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                  ${schedule.success_probability >= 0.8 ? 'bg-green-100 text-green-800' :
                    schedule.success_probability >= 0.6 ? 'bg-yellow-100 text-yellow-800' :
                    'bg-red-100 text-red-800'}`}>
                  {Math.round(schedule.success_probability * 100)}% Success Rate
                </span>
                <svg
                  className={`h-5 w-5 text-gray-400 transform transition-transform ${isExpanded ? 'rotate-180' : ''}`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* Expandable Content */}
        {isExpanded && (
          <div className="mt-6 border-t pt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Duration and Yield */}
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Duration</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.duration.months} months ({schedule.duration.days} days)
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Expected Yield</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.expected_yield.amount.toLocaleString()} {schedule.expected_yield.unit}
                  </p>
                </div>
              </div>

              {/* Financial Details */}
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Market Price</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.market_price.amount} {schedule.market_price.currency} {schedule.market_price.unit}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Production Cost</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.production_cost.amount.toLocaleString()} {schedule.production_cost.currency} {schedule.production_cost.unit}
                  </p>
                </div>
              </div>

              {/* Weather Requirements */}
              <div className="space-y-4">
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Temperature Range</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.weather.temperature.min}°{schedule.weather.temperature.unit} - {schedule.weather.temperature.max}°{schedule.weather.temperature.unit}
                  </p>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <label className="text-xs font-medium text-gray-500">Rainfall Range</label>
                  <p className="mt-1 text-sm text-gray-900">
                    {schedule.weather.rainfall.min}-{schedule.weather.rainfall.max} {schedule.weather.rainfall.unit}
                  </p>
                </div>
              </div>
            </div>

            {/* Disease & Pest Checks Timeline */}
            {schedule.disease_pest_checks.length > 0 && (
              <div className="mt-6">
                <label className="text-xs font-medium text-gray-500">Disease & Pest Checks</label>
                <div className="mt-4 relative">
                  <div className="absolute top-0 left-4 h-full w-0.5 bg-gray-200" />
                  <div className="space-y-6">
                    {schedule.disease_pest_checks.map((check, index) => (
                      <div key={index} className="relative flex items-start">
                        <div className="absolute left-4 -ml-2 h-4 w-4 rounded-full bg-white border-2 border-green-500" />
                        <div className="ml-10">
                          <p className="text-sm font-medium text-gray-900">
                            {new Date(check.date).toLocaleDateString()}
                          </p>
                          <p className="mt-1 text-sm text-gray-500">{check.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

function LandDetails() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('details');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [land, setLand] = useState(null);
  const [expandedSchedule, setExpandedSchedule] = useState(null);

  useEffect(() => {
    const fetchLandDetails = async () => {
      try {
        const response = await axiosInstance.get(`/lands/${id}`);
        setLand(response.data);
      } catch (err) {
        console.error('Error fetching land details:', err);
        setError('Failed to load land details');
      } finally {
        setLoading(false);
      }
    };

    fetchLandDetails();
  }, [id]);

  const tabs = [
    { id: 'details', label: 'Land Details', icon: 'M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7' },
    { id: 'schedule', label: 'Planting Schedule', icon: 'M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z' },
    { id: 'analytics', label: 'Analytics', icon: 'M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z' }
  ];

  if (loading) {
    return (
      <DashboardLayout>
        <div className="max-w-7xl mx-auto flex items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500"></div>
        </div>
      </DashboardLayout>
    );
  }

  if (error) {
    return (
      <DashboardLayout>
        <div className="max-w-7xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="mb-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <Link to="/lands" className="p-2 text-gray-400 hover:text-gray-500">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
              </Link>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{land.name}</h1>
                <p className="mt-1 text-sm text-gray-500">
                  {land.location.county}, {land.location.constituency}
                </p>
              </div>
            </div>
            <div className="flex items-center space-x-3">
              <Link
                to={`/lands/${id}/edit`}
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700"
              >
                Edit Land
              </Link>
            </div>
          </div>

          {/* Quick Stats */}
          <div className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Size</dt>
                      <dd className="text-lg font-medium text-gray-900">{land.area} acres</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9" />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Status</dt>
                      <dd className="text-lg font-medium text-gray-900">
                        {land.status ? 'Active' : 'Inactive'}
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white overflow-hidden shadow rounded-lg">
              <div className="p-5">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2 1 3 3 3h10c2 0 3-1 3-3V7c0-2-1-3-3-3H7c-2 0-3 1-3 3z" />
                    </svg>
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="text-sm font-medium text-gray-500 truncate">Soil Type</dt>
                      <dd className="text-lg font-medium text-gray-900">{land.soil_type || 'Not specified'}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs */}
          <div className="mt-6 border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {tabs.map(tab => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`${
                    activeTab === tab.id
                      ? 'border-green-500 text-green-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                >
                  <svg className="mr-2 h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={tab.icon} />
                  </svg>
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Tab Content */}
        <div className="space-y-6">
          {activeTab === 'details' && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              {/* Land Information */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Land Information</h3>
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Location</span>
                    <span className="text-sm font-medium text-gray-900">
                      {land.location.ward}, {land.location.constituency}, {land.location.county}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Coordinates</span>
                    <span className="text-sm font-medium text-gray-900">
                      {land.location.latitude}, {land.location.longitude}
                    </span>
                  </div>
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Current Crop</span>
                    <span className="text-sm font-medium text-gray-900">
                      {land.active_crop || 'None'}
                    </span>
                  </div>
                </div>
              </div>

              {/* Schedule Summary */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Schedule Summary</h3>
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <span className="text-sm text-gray-500">Active Schedules</span>
                    <span className="text-sm font-medium text-gray-900">
                      {land.schedule?.length || 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'schedule' && (
            <div className="space-y-6">
              {/* Action Buttons - Always visible */}
              <div className="bg-white rounded-xl shadow-sm p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-medium text-gray-900">Planting Schedule</h3>
                  <div className="flex space-x-4">
                    <Link
                      to={`/lands/${id}/schedule/new`}
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700"
                    >
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                      </svg>
                      Create Schedule
                    </Link>
                    <Link
                      to={`/lands/${id}/schedule/generate`}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
                    >
                      <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" />
                      </svg>
                      Generate with AI
                    </Link>
                  </div>
                </div>
              </div>

              {/* Schedule Cards */}
              {land.schedule && land.schedule.length > 0 ? (
                <div className="space-y-4">
                  {land.schedule.map((schedule, index) => (
                    <ScheduleCard
                      key={schedule._id}
                      schedule={schedule}
                      isExpanded={expandedSchedule === index}
                      onToggle={() => setExpandedSchedule(expandedSchedule === index ? null : index)}
                    />
                  ))}
                </div>
              ) : (
                <div className="bg-white rounded-xl shadow-sm p-8 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">No schedule yet</h3>
                  <p className="mt-1 text-sm text-gray-500">Get started by creating a new schedule or let AI generate one for you.</p>
                </div>
              )}
            </div>
          )}

          {activeTab === 'analytics' && (
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-4">Crop Performance Analytics</h3>
              {/* Keep your existing analytics content */}
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default LandDetails;
