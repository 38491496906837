import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import DashboardLayout from './layout/DashboardLayout';
import axiosInstance from '../services/axios';

function GenerateSchedule() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [land, setLand] = useState(null);
  const [loadingLand, setLoadingLand] = useState(true);
  const [generatedSchedule, setGeneratedSchedule] = useState(null);
  const [savingCropIndex, setSavingCropIndex] = useState(null);
  const [savedCrops, setSavedCrops] = useState([]);

  const [formData, setFormData] = useState({
    start_date: '',
    schedule_length: 12,
    min_growth_duration: 3,
    max_growth_duration: 6,
    categories: []
  });

  // Available crop categories
  const availableCategories = [
    { id: 'grain', label: 'Grain', description: 'Maize, Wheat, Rice, etc.' },
    { id: 'vegetable', label: 'Vegetable', description: 'Tomatoes, Cabbage, Kale, etc.' },
    { id: 'cash_crop', label: 'Cash Crop', description: 'Coffee, Tea, Cotton, etc.' },
    { id: 'legume', label: 'Legume', description: 'Beans, Peas, Lentils, etc.' },
    { id: 'fruit', label: 'Fruit', description: 'Bananas, Mangoes, Avocados, etc.' },
    { id: 'root_tuber', label: 'Root/Tuber', description: 'Potatoes, Cassava, Sweet Potatoes, etc.' },
    { id: 'herb_spice', label: 'Herb/Spice', description: 'Herbs and Spices' }
  ];

  useEffect(() => {
    const fetchLandDetails = async () => {
      try {
        const response = await axiosInstance.get(`/lands/${id}`);
        setLand(response.data);
      } catch (err) {
        console.error('Error fetching land details:', err);
        setError('Failed to load land details');
      } finally {
        setLoadingLand(false);
      }
    };

    fetchLandDetails();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCategoryToggle = (categoryLabel) => {
    setFormData(prev => {
      const categories = prev.categories.includes(categoryLabel)
        ? prev.categories.filter(c => c !== categoryLabel)
        : [...prev.categories, categoryLabel];
      return { ...prev, categories };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const requestBody = {
        ...formData,
        latitude: land.location.latitude,
        longitude: land.location.longitude
      };

      const response = await axiosInstance.post(`/generate-schedule`, requestBody);
      setGeneratedSchedule(response.data);
    } catch (err) {
      console.error('Error generating schedule:', err);
      setError(err.response?.data?.message || 'Failed to generate schedule. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleSaveCrop = async (crop, index) => {
    try {
      setSavingCropIndex(index);

      // Prepare the data in the exact format the API expects
      const scheduleData = {
        crop_name: crop.crop_name,
        variety: crop.variety,
        start_date: crop.start_date,
        end_date: crop.end_date,
        duration: {
          days: crop.duration.days,
          months: crop.duration.months
        },
        expected_yield: {
          amount: crop.expected_yield.amount,
          unit: crop.expected_yield.unit
        },
        market_price: {
          amount: crop.market_price.amount,
          currency: crop.market_price.currency,
          unit: crop.market_price.unit
        },
        production_cost: {
          amount: crop.production_cost.amount,
          currency: crop.production_cost.currency,
          unit: crop.production_cost.unit
        },
        success_probability: crop.success_probability,
        confidence_level: crop.confidence_level,
        weather: {
          temperature: {
            min: crop.weather.temperature.min,
            max: crop.weather.temperature.max,
            unit: crop.weather.temperature.unit
          },
          rainfall: {
            min: crop.weather.rainfall.min,
            max: crop.weather.rainfall.max,
            unit: crop.weather.rainfall.unit
          }
        },
        disease_pest_checks: crop.disease_pest_checks.map(check => ({
          date: check.date,
          description: check.description
        }))
      };

      await axiosInstance.post(`/lands/${id}/schedule`, scheduleData);
      setSavedCrops(prev => [...prev, index]);

    } catch (err) {
      console.error('Error saving crop schedule:', err.response?.data || err);
      setError(err.response?.data?.message || 'Failed to save crop schedule. Please try again.');
    } finally {
      setSavingCropIndex(null);
    }
  };

  const handleRegenerateSchedule = () => {
    setGeneratedSchedule(null);
  };

  if (loadingLand) {
    return (
      <DashboardLayout>
        <div className="max-w-7xl mx-auto flex items-center justify-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500"></div>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-4">
            <Link to={`/lands/${id}`} className="p-2 text-gray-400 hover:text-gray-500">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
            </Link>
            <h1 className="text-2xl font-bold text-gray-900">Generate AI Schedule</h1>
          </div>
        </div>

        {generatedSchedule ? (
          <div className="space-y-6">
            <div className="bg-white rounded-xl shadow-sm">
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-lg font-medium text-gray-900">Generated Schedule Options</h2>
                  <button
                    onClick={handleRegenerateSchedule}
                    className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Generate Another Schedule
                  </button>
                </div>

                <div className="space-y-6">
                  {generatedSchedule.schedule.map((crop, index) => (
                    <div key={index} className="border rounded-lg p-6">
                      <div className="flex justify-between items-start mb-4">
                        <h3 className="text-lg font-medium text-gray-900">
                          {crop.crop_name} ({crop.variety})
                        </h3>
                        {savedCrops.includes(index) ? (
                          <div className="flex items-center text-green-600">
                            <svg className="w-5 h-5 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            <span className="text-sm font-medium">Saved</span>
                          </div>
                        ) : (
                          <button
                            onClick={() => handleSaveCrop(crop, index)}
                            disabled={savingCropIndex === index}
                            className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
                          >
                            {savingCropIndex === index ? (
                              <div className="flex items-center">
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                                </svg>
                                Saving...
                              </div>
                            ) : (
                              'Save This Crop'
                            )}
                          </button>
                        )}
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        <div className="space-y-3">
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Duration & Dates</h4>
                            <p className="text-sm text-gray-500">
                              {crop.duration.months} months ({crop.duration.days} days)
                            </p>
                            <p className="text-sm text-gray-500">
                              Start: {new Date(crop.start_date).toLocaleDateString()}
                            </p>
                            <p className="text-sm text-gray-500">
                              End: {new Date(crop.end_date).toLocaleDateString()}
                            </p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Success Metrics</h4>
                            <p className="text-sm text-gray-500">
                              Success Probability: {(crop.success_probability * 100).toFixed(0)}%
                            </p>
                            <p className="text-sm text-gray-500">
                              Confidence Level: {(crop.confidence_level * 100).toFixed(0)}%
                            </p>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Expected Outcomes</h4>
                            <p className="text-sm text-gray-500">
                              Yield: {crop.expected_yield.amount} {crop.expected_yield.unit}
                            </p>
                            <p className="text-sm text-gray-500">
                              Market Price: {crop.market_price.amount} {crop.market_price.currency} {crop.market_price.unit}
                            </p>
                            <p className="text-sm text-gray-500">
                              Production Cost: {crop.production_cost.amount} {crop.production_cost.currency} {crop.production_cost.unit}
                            </p>
                          </div>
                        </div>

                        <div className="space-y-3">
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Weather Requirements</h4>
                            <p className="text-sm text-gray-500">
                              Temperature: {crop.weather.temperature.min}°-{crop.weather.temperature.max}° {crop.weather.temperature.unit}
                            </p>
                            <p className="text-sm text-gray-500">
                              Rainfall: {crop.weather.rainfall.min}-{crop.weather.rainfall.max} {crop.weather.rainfall.unit}
                            </p>
                          </div>
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">Disease & Pest Checks</h4>
                            <ul className="list-disc pl-5 space-y-1">
                              {crop.disease_pest_checks.map((check, checkIndex) => (
                                <li key={checkIndex} className="text-sm text-gray-500">
                                  {new Date(check.date).toLocaleDateString()}: {check.description}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          /* Existing form JSX */
          <div className="bg-white rounded-xl shadow-sm">
            <form onSubmit={handleSubmit} className="p-6 space-y-6">
              {error && (
                <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-md">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}

              <div className="space-y-6">
                <div>
                  <label htmlFor="start_date" className="block text-sm font-medium text-gray-700">
                    Start Date
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    id="start_date"
                    required
                    value={formData.start_date}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                </div>

                <div>
                  <label htmlFor="schedule_length" className="block text-sm font-medium text-gray-700">
                    Schedule Length (months)
                  </label>
                  <input
                    type="number"
                    name="schedule_length"
                    id="schedule_length"
                    required
                    min="1"
                    max="24"
                    value={formData.schedule_length}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="min_growth_duration" className="block text-sm font-medium text-gray-700">
                      Minimum Growth Duration (months)
                    </label>
                    <input
                      type="number"
                      name="min_growth_duration"
                      id="min_growth_duration"
                      required
                      min="1"
                      max="12"
                      value={formData.min_growth_duration}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    />
                  </div>

                  <div>
                    <label htmlFor="max_growth_duration" className="block text-sm font-medium text-gray-700">
                      Maximum Growth Duration (months)
                    </label>
                    <input
                      type="number"
                      name="max_growth_duration"
                      id="max_growth_duration"
                      required
                      min="1"
                      max="12"
                      value={formData.max_growth_duration}
                      onChange={handleChange}
                      className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Crop Categories
                  </label>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {availableCategories.map(category => (
                      <div
                        key={category.id}
                        className={`p-4 border rounded-lg cursor-pointer transition-colors ${
                          formData.categories.includes(category.label)
                            ? 'border-green-500 bg-green-50'
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                        onClick={() => handleCategoryToggle(category.label)}
                      >
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            checked={formData.categories.includes(category.label)}
                            onChange={() => {}}
                            className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                          />
                          <div className="ml-3">
                            <label className="font-medium text-gray-700">{category.label}</label>
                            <p className="text-sm text-gray-500">{category.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <Link
                  to={`/lands/${id}`}
                  className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={loading || formData.categories.length === 0}
                  className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700 disabled:opacity-50"
                >
                  {loading ? 'Generating...' : 'Generate Schedule'}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default GenerateSchedule;
