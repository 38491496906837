// Base API configuration
// export const API_BASE_URL = 'http://ec2-54-89-126-162.compute-1.amazonaws.com:8000';
// export const API_BASE_URL = 'http://ec2-34-224-64-203.compute-1.amazonaws.com/api';
// export const API_BASE_URL = 'http://localhost:3001';
export const API_BASE_URL = 'http://20.51.113.166/api';

export const ENDPOINTS = {
  REGISTER: '/register',
  LOGIN: '/token',
  GENERATE_SCHEDULE: '/generate-schedule'
};
