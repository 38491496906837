import axios from 'axios';
import { API_BASE_URL } from '../config/api';

// Create axios instance with common configuration
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  // Bypass SSL verification
  validateStatus: () => true,
  proxy: false
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Get token from localStorage if it exists
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    // Bypass SSL verification for all requests
    config.validateStatus = () => true;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle common errors here
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Handle unauthorized
          localStorage.removeItem('token');
          window.location.href = '/login';
          break;
        case 403:
          // Handle forbidden
          console.error('Access forbidden');
          break;
        case 500:
          // Handle server error
          console.error('Server error:', error.response.data);
          break;
        default:
          console.error('Request failed:', error.response.data);
          break;
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
