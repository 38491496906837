import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from './layout/DashboardLayout';
import axiosInstance from '../services/axios';

function NewLand() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    size: '',
    soilType: '',
    currentCrop: '',
    county: '',
    constituency: '',
    ward: ''
  });

  const [locationData, setLocationData] = useState({
    counties: [],
    constituencies: [],
    wards: []
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch counties data on component mount
  useEffect(() => {
    fetch('/geojson.json')
      .then(res => res.json())
      .then(data => {
        setLocationData(prev => ({
          ...prev,
          counties: data.map(county => ({
            id: county.id,
            name: county.name,
            latitude: county.latitude,
            longitude: county.longitude
          }))
        }));
      })
      .catch(err => setError('Failed to load location data'));
  }, []);

  // Update constituencies when county changes
  useEffect(() => {
    if (formData.county) {
      fetch('/geojson.json')
        .then(res => res.json())
        .then(data => {
          const selectedCounty = data.find(county => county.id === formData.county);
          setLocationData(prev => ({
            ...prev,
            constituencies: selectedCounty?.constituencies || [],
            wards: [] // Reset wards when county changes
          }));
          setFormData(prev => ({ ...prev, constituency: '', ward: '' })); // Reset selections
        });
    }
  }, [formData.county]);

  // Update wards when constituency changes
  useEffect(() => {
    if (formData.constituency) {
      const selectedConstituency = locationData.constituencies
        .find(cons => cons.id === formData.constituency);
      setLocationData(prev => ({
        ...prev,
        wards: selectedConstituency?.wards || []
      }));
      setFormData(prev => ({ ...prev, ward: '' })); // Reset ward selection
    }
  }, [formData.constituency]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Get county coordinates and names for the selected locations
      const selectedCounty = locationData.counties
        .find(county => county.id === formData.county);
      const selectedConstituency = locationData.constituencies
        .find(cons => cons.id === formData.constituency);
      const selectedWard = locationData.wards
        .find(ward => ward.id === formData.ward);

      const landData = {
        name: formData.name,
        area: parseFloat(formData.size),
        location: {
          latitude: parseFloat(selectedCounty.latitude),
          longitude: parseFloat(selectedCounty.longitude),
          county: selectedCounty.name,
          constituency: selectedConstituency.name,
          ward: selectedWard.name
        },
        status: true,
        active_crop: formData.currentCrop || null,
        soil_type: formData.soilType,
        schedule: []
      };

      // Add console.log to debug the data being sent
      console.log('Sending land data:', landData);

      // Make API call to save the land
      await axiosInstance.post('/lands', landData);
      navigate('/lands');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to create land. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-3xl mx-auto">
        <div className="md:flex md:items-center md:justify-between mb-6">
          <h2 className="text-2xl font-bold text-gray-900">Add New Land</h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6 bg-white rounded-xl shadow-sm p-6">
          {error && (
            <div className="bg-red-50 border-l-4 border-red-400 p-4 text-sm text-red-700">
              {error}
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Land Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                required
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                Size (acres)
              </label>
              <input
                type="number"
                step="0.1"
                name="size"
                id="size"
                required
                value={formData.size}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="county" className="block text-sm font-medium text-gray-700">
                County
              </label>
              <select
                name="county"
                id="county"
                required
                value={formData.county}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option value="">Select County</option>
                {locationData.counties.map(county => (
                  <option key={county.id} value={county.id}>{county.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="constituency" className="block text-sm font-medium text-gray-700">
                Constituency
              </label>
              <select
                name="constituency"
                id="constituency"
                required
                value={formData.constituency}
                onChange={handleChange}
                disabled={!formData.county}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option value="">Select Constituency</option>
                {locationData.constituencies.map(constituency => (
                  <option key={constituency.id} value={constituency.id}>
                    {constituency.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="ward" className="block text-sm font-medium text-gray-700">
                Ward
              </label>
              <select
                name="ward"
                id="ward"
                required
                value={formData.ward}
                onChange={handleChange}
                disabled={!formData.constituency}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option value="">Select Ward</option>
                {locationData.wards.map(ward => (
                  <option key={ward.id} value={ward.id}>{ward.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="soilType" className="block text-sm font-medium text-gray-700">
                Soil Type
              </label>
              <select
                name="soilType"
                id="soilType"
                required
                value={formData.soilType}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
              >
                <option value="">Select Soil Type</option>
                <option value="Loam">Loam</option>
                <option value="Clay">Clay</option>
                <option value="Sandy">Sandy</option>
                <option value="Silt">Silt</option>
                <option value="Peat">Peat</option>
              </select>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate('/lands')}
              className="px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-lg text-white bg-green-600 hover:bg-green-700"
            >
              {loading ? 'Saving...' : 'Save Land'}
            </button>
          </div>
        </form>
      </div>
    </DashboardLayout>
  );
}

export default NewLand;
