import { useState } from 'react';
import axiosInstance from '../services/axios';
import { ENDPOINTS } from '../config/api';
import { format } from 'date-fns';

// Kenya counties and their sub-counties (sample data)
const locations = {
  "Uasin Gishu": {
    subCounties: {
      "Ainabkoi": ["Ainabkoi", "Kaptagat", "Kapsoya"],
      "Kapseret": ["Langas", "Kipkenyo", "Ngeria"],
      "Moiben": ["Karuna", "Tembelio", "Sergoit"]
    }
  },
  "Trans Nzoia": {
    subCounties: {
      "Kiminini": ["Kiminini", "Waitaluk", "Sirende"],
      "Kwanza": ["Kwanza", "Keiyo", "Bidii"],
      "Saboti": ["Saboti", "Tuwani", "Machewa"]
    }
  },
  "Nandi": {
    subCounties: {
      "Chesumei": ["Kaptel", "Kiptuiya", "Kosirai"],
      "Nandi Hills": ["Nandi Hills", "Chepkunyuk", "Kapchorua"],
      "Emgwen": ["Kapkangani", "Kapsabet", "Kilibwoni"]
    }
  }
};

// Simplified categories
const categories = [
  "Cereals",
  "Legumes",
  "Root Crops",
  "Vegetables",
  "Fruits",
  "Cash Crops",
  "Herbs & Spices"
];

function Schedule() {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState({
    county: '',
    subCounty: '',
    ward: ''
  });
  const [formData, setFormData] = useState({
    start_date: format(new Date(), 'yyyy-MM-dd'),
    schedule_length: 8,
    min_growth_duration: 2,
    max_growth_duration: 10,
    categories: ['Vegetables, fruits, grain, cereal']
  });
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [error, setError] = useState('');

  const handleLocationChange = (type, value) => {
    if (type === 'county') {
      setSelectedLocation({
        county: value,
        subCounty: '',
        ward: ''
      });
    } else if (type === 'subCounty') {
      setSelectedLocation({
        ...selectedLocation,
        subCounty: value,
        ward: ''
      });
    } else {
      setSelectedLocation({
        ...selectedLocation,
        ward: value
      });
    }
  };

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev => {
      if (prev.includes(category)) {
        return prev.filter(c => c !== category);
      }
      return [...prev, category];
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post(ENDPOINTS.GENERATE_SCHEDULE, {
        ...formData,
        categories: selectedCategories,
        latitude: -0.32099679999999997,
        longitude: 35.226127597753845
      });
      setSchedule(response.data);
    } catch (error) {
      console.error('Error generating schedule:', error);
      setError(error.response?.data?.message || 'Failed to generate schedule');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-green-50 py-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Farm Schedule Generator Demo</h1>
          <p className="text-xl text-gray-600">Try our AI-powered crop scheduling system</p>
        </div>

        <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-md p-6 mb-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Location Selection */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900">Select Your Location</h3>

              <div>
                <label className="block text-sm font-medium text-gray-700">County</label>
                <select
                  value={selectedLocation.county}
                  onChange={(e) => handleLocationChange('county', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                >
                  <option value="">Select County</option>
                  {Object.keys(locations).map(county => (
                    <option key={county} value={county}>{county}</option>
                  ))}
                </select>
              </div>

              {selectedLocation.county && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Sub-County</label>
                  <select
                    value={selectedLocation.subCounty}
                    onChange={(e) => handleLocationChange('subCounty', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Sub-County</option>
                    {Object.keys(locations[selectedLocation.county].subCounties).map(subCounty => (
                      <option key={subCounty} value={subCounty}>{subCounty}</option>
                    ))}
                  </select>
                </div>
              )}

              {selectedLocation.subCounty && (
                <div>
                  <label className="block text-sm font-medium text-gray-700">Ward</label>
                  <select
                    value={selectedLocation.ward}
                    onChange={(e) => handleLocationChange('ward', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                  >
                    <option value="">Select Ward</option>
                    {locations[selectedLocation.county].subCounties[selectedLocation.subCounty].map(ward => (
                      <option key={ward} value={ward}>{ward}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>

            {/* Simplified Categories Selection */}
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-900">Select Crop Categories</h3>
              <p className="text-sm text-gray-500">Choose the types of crops you're interested in growing</p>

              <div className="grid grid-cols-2 gap-3">
                {categories.map((category) => (
                  <label key={category} className="relative flex items-start py-2">
                    <div className="min-w-0 flex-1 text-sm">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          checked={selectedCategories.includes(category)}
                          onChange={() => handleCategoryChange(category)}
                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                        />
                        <span className="ml-3 font-medium text-gray-700">{category}</span>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            {/* Rest of the form fields */}
            <div>
              <label className="block text-sm font-medium text-gray-700">Start Date</label>
              <input
                type="date"
                value={formData.start_date}
                onChange={(e) => setFormData({...formData, start_date: e.target.value})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Schedule Length (months)</label>
              <input
                type="number"
                value={formData.schedule_length}
                onChange={(e) => setFormData({...formData, schedule_length: parseInt(e.target.value)})}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Min Growth Duration</label>
                <input
                  type="number"
                  value={formData.min_growth_duration}
                  onChange={(e) => setFormData({...formData, min_growth_duration: parseInt(e.target.value)})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Max Growth Duration</label>
                <input
                  type="number"
                  value={formData.max_growth_duration}
                  onChange={(e) => setFormData({...formData, max_growth_duration: parseInt(e.target.value)})}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>
            </div>

            <button
              type="submit"
              disabled={loading || !selectedLocation.ward || selectedCategories.length === 0}
              className="w-full bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition-colors disabled:bg-green-400"
            >
              {loading ? 'Generating...' : 'Generate Schedule'}
            </button>

            {selectedCategories.length > 0 && (
              <div className="bg-green-50 p-4 rounded-md">
                <h4 className="text-sm font-medium text-green-800 mb-2">Selected Categories:</h4>
                <div className="flex flex-wrap gap-2">
                  {selectedCategories.map((category) => (
                    <span
                      key={category}
                      className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800"
                    >
                      {category}
                      <button
                        type="button"
                        onClick={() => handleCategoryChange(category)}
                        className="ml-1 inline-flex items-center p-0.5 hover:bg-green-200 rounded-full"
                      >
                        ×
                      </button>
                    </span>
                  ))}
                </div>
              </div>
            )}
          </form>
        </div>

        {/* Schedule display section remains the same */}
        {schedule && (
          <div className="space-y-8">
            <div className="bg-white rounded-xl shadow-md p-6">
              <h2 className="text-2xl font-bold mb-4">Generated Schedule for {selectedLocation.ward}, {selectedLocation.subCounty}</h2>
              <p className="text-gray-600">
                Total Duration: {schedule.total_duration.months} months ({schedule.total_duration.days} days)
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
              {schedule.schedule.map((crop, index) => (
                <div key={index} className="bg-white rounded-xl shadow-md p-6">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="text-xl font-semibold">{crop.crop_name}</h3>
                      <p className="text-gray-600">{crop.variety}</p>
                    </div>
                    <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded">
                      {crop.duration.months} months
                    </span>
                  </div>

                  <div className="space-y-2 text-sm">
                    <p><span className="font-medium">Start:</span> {crop.start_date}</p>
                    <p><span className="font-medium">End:</span> {crop.end_date}</p>
                    <p><span className="font-medium">Expected Yield:</span> {crop.expected_yield.amount} {crop.expected_yield.unit}</p>
                    <p><span className="font-medium">Market Price:</span> {crop.market_price.amount} {crop.market_price.currency} {crop.market_price.unit}</p>
                    <p><span className="font-medium">Success Probability:</span> {(crop.success_probability * 100).toFixed(0)}%</p>
                  </div>

                  <div className="mt-4">
                    <h4 className="font-medium mb-2">Disease & Pest Checks:</h4>
                    <ul className="text-sm space-y-1">
                      {crop.disease_pest_checks.map((check, idx) => (
                        <li key={idx} className="text-gray-600">
                          {check.date}: {check.description}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Schedule;
